<template>
  <div class="Login">

    <div class="main">

      <div class="title">
        <img src="../assets/Login/title.png" alt="">
      </div>

      <div class="content">
        <div class="logo">
          <img src="../assets/Login/logo.png" alt="">
        </div>

        <div class="form">
          <el-form :model="form" ref="form" :rules="rules" label-width="80px" :hide-required-asterisk="true" @submit.native.prevent >
            <el-form-item label="用户名" prop="username" >
              <el-input id="username" clearable v-model="form.username" placeholder="请输入用户名"></el-input>
            </el-form-item>
            <el-form-item label="密码" prop="password" >
              <el-input id="password" clearable type="password" show-password v-model="form.password" placeholder="请输入密码"></el-input>
            </el-form-item>
            <el-form-item>
              <el-button id="buttons" class="button" type="primary" native-type="submit" @click="onSubmit(form)">登录</el-button>
            </el-form-item>
          </el-form>
        </div>
      </div>

<!--      <el-button @click="dial">-->
<!--        拨打电话-->
<!--      </el-button>-->

    </div>

  </div>
</template>

<script>
import getMenu from "@/utils/getMenu";
import store from "@/store/store";

export default {
  name: "Login",
  data() {
    return{
      form: {
        username: null,
        password: null,
      },
      rules: {
        username: [
          { required: true, message: '请输入用户名', trigger: 'blur' },
        ],
        password: [
          { required: true, message: '请输入密码', trigger: 'blur' },
          { min: 6, max: 20, message: '密码长度在 6 到 20 位', trigger: 'blur' }
        ],
      },
    }
  },
  created() {
    store.commit("setMenu",[]);
    store.commit("setToken",null);
  },
  mounted() {
    this.$driver.defineSteps([
      {
        element: '#username',
        popover: {
          title: '用户名',
          description: '请输入用户名',
          position: 'bottom-center'
        }
      },
      {
        element: '#password',
        popover: {
          title: '密码',
          description: '请输入密码',
          position: 'bottom-center'
        }
      },
      {
        element: '#buttons',
        popover: {
          title: '登录',
          description: '点击登录',
          position: 'bottom-center'
        }
      },
    ]);
    console.log('111',process.env.VUE_APP_APIURL);
    // this.$driver.start();
  },
  methods: {
    dial() {
      window.location.href = 'tel:17303173032';
    },
    onSubmit(formName) {
      this.$refs.form.validate((valid) => {
        if(valid) {
          this.$post("login/admin",formName)
            .then(res => {
              if(res) {
                console.log(res);
                this.$store.commit("setUserInfo",res.data);
                this.$store.commit("setMenu",res.data.menu);

                getMenu(res.data.menu)
                    // eslint-disable-next-line no-unused-vars
                  .then((res) => {
                    // this.$router.push({
                    //   path: '/' + res.data.menu[0].subMenu[0].menuUrl
                    // })

                    this.$message({
                      message: '登陆成功',
                      type: 'success'
                    });

                    // this.$router.push({
                    //   path: res.redirect
                    // })

                    this.$router.push({
                      path: "/index"
                    })

                  })

              }
            })
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
  @import "../style/Login";
</style>
